import { createEventHandler } from '../../utils/pollard'

interface Arguments {
  position?: number
}

export default (iframe: HTMLIFrameElement | null) =>
  createEventHandler(({ position = 0 }: Arguments) => {
    if (!iframe) {
      return
    }

    if (window.location.href.includes('smava.de') && window.parent) {
      window.parent.postMessage('SCROLL_TO:' + position, '*')
      return
    }

    if (position === 0 && iframe.scrollIntoView) {
      iframe.scrollIntoView()
      return
    }

    window.scrollTo(0, position + iframe.offsetTop)
  })
